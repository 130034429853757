import React from 'react'

const Contact = props => (
    <section>
        <div className="inner">
            <div className="grid-wrapper">
                <div className="alt col-3">
                    <div className="contact-method">
                        <span className="icon alt fa-facebook"></span>
                        <h3>Redes sociales</h3>
                        <a href="https://www.facebook.com/tramitesdorado/" target="_blank" rel="noopener noreferrer">
                            Facebook
                        </a>
                        <br />
                        <a href="https://www.instagram.com/tramitesdorado/" target="_blank" rel="noopener noreferrer">
                            Instagram
                        </a>
                    </div>
                </div>
                <div className="alt col-3">
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h3>Correo electrónico</h3>
                        <a href="mailto:tramitesdorado@gmail.com" target="_top">
                            tramitesdorado@gmail.com
                        </a>
                    </div>
                </div>
                <div className="alt col-3">
                    <div className="contact-method">
                        <span className="icon alt fa-phone"></span>
                        <h3>Contacto directo</h3>
                        Mensajes y llamadas:
                        <br />
                        <ul>
                            <li>
                                <a
                                    href="https://api.whatsapp.com/send?phone=573103904606&text=Hola%2C%20acabo%20de%20ver%20tu%20sitio%20web%20y%20me%20interesan%20sus%20servicios"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    (+ 57) 310 390 4606
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://api.whatsapp.com/send?phone=573116777777&text=Hola%2C%20acabo%20de%20ver%20tu%20sitio%20web%20y%20me%20interesan%20sus%20servicios"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    (+ 57) 311 677 7777
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://api.whatsapp.com/send?phone=573137688730&text=Hola%2C%20acabo%20de%20ver%20tu%20sitio%20web%20y%20me%20interesan%20sus%20servicios"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    (+ 57) 313 768 8730
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="alt col-3">
                    <div className="contact-method">
                        <span className="icon alt fa-home"></span>
                        <h3>Dirección</h3>
                        <span>
                            Calle 17 # 17 - 03 diagonal al tránsito <br />
                            Timbio, Cauca, Colombia
                            <br />
                        </span>
                        Consulte el{' '}
                        <a
                            href="https://maps.app.goo.gl/uYP1o1fYX8YuJMCV6"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Mapa
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default Contact
