import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = (props) => (
    <nav id="menu">
        <div className="inner">
            <ul className="links">
                <li><Link onClick={props.onToggleMenu} to="/">Inicio</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/vehicles">Trámites para vehículos</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/licenses">Trámites para licencias</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/external_links">Enlaces externos</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/other_services">Otros servicios</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/about">Sobre nosotros</Link></li>
            </ul>
            <ul className="actions vertical">
                <li><a href="https://api.whatsapp.com/send?phone=573103904606&text=Hola%2C%20acabo%20de%20ver%20tu%20sitio%20web%20y%20me%20interesan%20sus%20servicios" target="_blank" rel="noopener noreferrer" className="button special fit icon fa-whatsapp">Enviar mensaje</a></li>
            </ul>
        </div>
        <a className="close" onClick={props.onToggleMenu} >Close</a>
    </nav>
)

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu
