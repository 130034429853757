import React from 'react'

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
            <ul className="copyright">
                <li>&copy; Copyright</li><li>Designed by HTML5 UP</li><li>Adapted by <a href="http://sahydo.com/" target="_blank" rel="noopener noreferrer">sahydo</a></li>
            </ul>
        </div>
    </footer>
)

export default Footer
